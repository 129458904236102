import styled from "styled-components";
import Title from "../components/landing/title";
import Downloads from "../components/landing/downloads";
import Assignment from "../components/landing/assignment";
import Reason from "../components/landing/reason";
import Method from "../components/landing/method";
import Footer from "../components/footer";
import { isDesktop } from "react-device-detect";
import Faq from "../components/landing/faq";
import { useRef } from "react";

export default function LandingPage() {
  
  return (
    <>
      <Layout>
        <img style={{ width: "100%", zIndex: -1 }} src={isDesktop ? "/images/bg-header.svg" : "/images/bg-header-m.png"} />
        <div style={{
          position: "relative"
        }}>
          <Content>
            <Title />
            <div style={{ padding: "0 25px" }}>
              <Downloads />
            </div>
            <Assignment />
            <Reason />
          </Content>
          <img style={{ width: "100%", position: "absolute", bottom: 0, zIndex: -1 }} src="/images/bg-middle.svg" />
        </div>
        <div style={{
          position: "relative"
        }}>
          <Content>
            <Faq />
          </Content>
          <Method />
          {isDesktop && (
            <>
              <img style={{ position: "absolute", top: "1000px", left: 0, zIndex: -1 }} src="/images/bg-cicle-small.svg" />
              <img style={{ position: "absolute", top: "100px", right: 0, zIndex: -1 }} src="/images/bg-cicle-big.svg" />
            </>
          )}
        </div>

        <Footer />
      </Layout>

    </>
  );
}

const Layout = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  position: relative;

  > img {
    position: absolute;
    z-index: -1;
  }
`;

const Content = styled.div`
  width: 1000px;

  margin: 0 auto;
  padding-bottom: 320px;

  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    width:100%;
    padding-bottom: 120px;
  }
`;

const Background = styled.img`
  width: 100%;

  position: absolute;
  bottom: 0;
`;
