import styled from "styled-components";

interface ITitleProp {
  title: string;
  topText: string;
}

export default function ContentTitle({ title, topText }: ITitleProp) {
  const str = Array.from(title);

  return (
    <Layout>
      <div>
        <div className="mainText">LAUD YOU</div>를 {topText}
      </div>
      <Title>
        <CircleWrapper>
          {str.map((e,index) => {
            return (
              <Circle key={index}>
                <span>{e}</span>
              </Circle>
            );
          })}
          <ShadowCircleWrapper>
            {str.map((_,index) => {
              return <ShadowCircle key={index}/>;
            })}
          </ShadowCircleWrapper>
        </CircleWrapper>
      </Title>
    </Layout>
  );
}

const Layout = styled.div`
  margin: 0 auto;

  div {
    color: #555;
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;

    display: flex;
    justify-content: center;

    .mainText {
      color: #4bc500;
      font-weight: 700;
    }
  }

  @media (max-width: 800px) {
    div {
      font-size: 24px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  position: relative;

  margin-left: 26px;
  @media (max-width: 800px) {
    margin-left: 0px;
  }
`;

const CircleWrapper = styled.div`
  position: relative;

  margin: 0 0 8px 0;
`;

const Circle = styled.div`
  width: 128px;
  height: 128px;

  border-radius: 50%;

  background: #4bc500;

  align-items: center;
  margin: 27px 0 0 -26px;

  z-index: 1;

  span {
    color: #fff;
    font-size: 70px;
    font-weight: 800;
    line-height: 50px;

    position: relative;
    top: 5px;
    @media (max-width: 800px) {
      font-size: 40px;
      line-height: 10px;
      top: 3px;
    }  
  }
  @media (max-width: 800px) {
    width: 75px;
    height: 75px;
    margin: 17px 0 0 -16px;
  }  
`;

const ShadowCircleWrapper = styled.div`
  position: absolute;
  bottom: -8px;
`;

const ShadowCircle = styled.div`
  width: 128px;
  height: 128px;

  border-radius: 50%;

  background: #e7f5d4;

  align-items: center;
  margin: 0 0 0 -26px;

  @media (max-width: 800px) {
    width: 75px;
    height: 75px;
    margin: 17px 0 0 -16px;
  }  
`;
