import styled from "styled-components";
import Part from "./part";

export default function SecondBox() {
  return (
    <Layout>
      <div className="explanation">
        자녀가 공부하기를 원하는 학습과정을 구매하고 설정할 수 있습니다.
      </div>
      <Part text="✅ 학습과정 구매 및 학습 설정" />
      <Box>
        <img width={290} alt="img" src="/images/method/step-four/two-first.png" />
        <div className="plusImg">
          <span>
            학습과정 설정에서 수학, 영어, 사자성어를 구매하고 학습 과정을 설정할
            수 있습니다.
          </span>
          <div style={{
            marginTop: "15px",
            display: "block",
            borderRadius: "5px",
            backgroundColor: "#F3F3F5",
            padding: "20px",
            color: "#555555",
            fontSize: "14px",
          }}>
            <p style={{ padding: 0, margin: 0, paddingBottom: "13px", paddingLeft: "5px", fontWeight: "700" }}>[과목별 학습 과정 정보]</p>
            <p style={{ padding: 0, margin: 0, paddingBottom: "3px" }}><span style={{
              fontWeight: "700", color: "#555555",
              fontSize: "14px",
            }}>∙ 수학</span> : 초등학생 1학년 ~ 6학년 과정</p>
            <p style={{ padding: 0, margin: 0, paddingBottom: "3px" }}><span style={{
              fontWeight: "700", color: "#555555",
              fontSize: "14px",
            }}>∙ 영어</span> : 초등 기본 영어 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;및 미국 Kindergarten ~ Grade 5</p>
            <p style={{ padding: 0, margin: 0, paddingBottom: "3px" }}><span style={{
              fontWeight: "700", color: "#555555",
              fontSize: "14px",
            }}>∙ 사자성어</span> : 초급, 중급, 고급, 최고급 과정</p>
          </div>
        </div>
      </Box>
      <Part text="✅ 학습과정구매" />
      <Box>
        <img width={290} alt="img" src="/images/method/step-four/two-second.png" />
        <span>
          <b>과정 전체 구매</b>를 통해 한 단원 전체를 구매하거나, 단일{" "}
          <b>구매</b> 하여 단일 과정으로 구매할 수 있습니다. 구매된 학습과정은
          <b>추가</b> 버튼으로 변경됩니다.
        </span>
      </Box>
      <Part text="✅ 학습과정 공부하기 설정 및 해제" />
      <Box>
        <img width={290} alt="img" src="/images/method/step-four/two-third.png" />
        <div>
          <span>
            학습 과정 목록에서 구매 완료되어 있는 학습과정을 <b>추가</b> 시,
            학습과정이 라우드유 자녀앱에 공부하도록 추가되며 버튼은 <b>해제</b>
            로 변경됩니다. <b>해제</b> 클릭 시 해당 학습 과정이 라우드유
            자녀앱에서 제외되고 버튼은 다시 추가 상태로 변경됩니다.
          </span>
          <span>
            구매하신 학습 과정은 언제라도 다시 추가 설정하여 공부할 수 있습니다.
          </span>
        </div>
      </Box>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  .explanation {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    margin: 25px 0 45px;
  }
`;

const Box = styled.div`
  margin: 20px 0 50px;

  display: flex;
  align-items: start;
  gap: 20px;

  border-top: 1px solid #e3e3e3;
  padding-top: 20px;

  div {
    display: flex;
    flex-direction: column;

    gap: 29px;
  }
  .plusImg {
    display: flex;
    flex-direction: column;

    gap: 0;

    img {
      margin-top: 15px;
    }
  }

  span {
    color: #444;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    b {
      font-weight: 700;
    }
  }
  @media (max-width: 800px) {
    display:block;
    .plusImg {
      width: 100%;
    }
    img {
      width:100%;
    }
  }
`;
