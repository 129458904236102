import styled from "styled-components";
import ContentTitle from "./content-title";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface IPagination {
    page: number;
    total: number;
    limit: number;
    onPage: (index: number) => void;
}
const FaqPagination = (props: IPagination) => {
    // const [page, setPage] = useState(1);

    const lastIndex = useMemo(() => {
        if (props.total == 0) return 0;
        const n = Math.floor(props.total / props.limit);
        const nn = props.total % props.limit;
        return n + (nn > 0 ? 1 : 0);
    }, [props])

    const list = useMemo(() => {
        if (props.total == 0) return [];
        const startIndex = Math.floor(props.page / (props.limit + 1));

        const lestN = lastIndex > 5 ? lastIndex - (startIndex + 4) : lastIndex;

        return Array.from({ length: (lestN) > 5 ? 5 : lestN }).map((_, index) => index + (startIndex * props.limit) + 1);
    }, [props, lastIndex]);
    return <PaginationLayout>
        <img style={{ transform: "rotate(180deg)" }} src="images/faq/pre-more-ic.svg"
            onClick={() => {
                props.onPage(1)
            }}
        />
        <img src="images/faq/pre-ic.svg"
            onClick={() => {
                if (props.page > 1) props.onPage(props.page - 1)
            }}
        />
        {list.map(index => (
            <PaginationItem key={index} $active={index == props.page} onClick={() => props.onPage(index)}>{index}</PaginationItem>
        ))}
        <img style={{ transform: "rotate(180deg)" }} src="images/faq/pre-ic.svg"
            onClick={() => {
                if (props.page < lastIndex) props.onPage(props.page + 1)
            }}
        />
        <img src="images/faq/pre-more-ic.svg"
            onClick={() => {
                props.onPage(lastIndex)
            }}
        />
    </PaginationLayout>

}

const PaginationLayout = styled.div`
    display:flex;
    justify-content: center;
    img {
        cursor: pointer;
    }
`;
const PaginationItem = styled.span<{ $active: boolean }>`
    width:40px;
    height:40px;
    cursor: pointer;
    display : flex;
	justify-content : center;
	align-items : center;
    font-size:16px;
    font-weight: ${props => props.$active ? "800" : "600"};
    color:${props => props.$active ? "#4BC500" : "#9A9A9A"};
    text-decoration: ${props => props.$active ? "underline #4BC500" : "none"};
`;

const FaqItem = ({ title, body }: { title: string, body: string }) => {
    const [open, setOpen] = useState<boolean>(false);
    return <FaqLayout onClick={() => setOpen(!open)}>
        <FaqTitle>
            <span style={{ fontSize: "18px", fontWeight: 400, color: "#333333" }}><span style={{ fontWeight: 700, fontFamily: "Roboto", color: "#555555", paddingRight: "5px" }}>Q.</span>{title}</span>
            <FaqArrow open={open}>
                <img src="images/faq/arrow-ic.svg" />
            </FaqArrow>
        </FaqTitle>
        {open && (
            <FaqContent>
                <FaqContentTitle>
                    <span style={{ color: "#0D925E", fontWeight: 700, lineHeight: "26px" }}>A.</span>
                    <pre style={{ fontSize: "16px", fontWeight: 400, color: "#666666", lineHeight: "26px", width: "100%", whiteSpace: "pre-wrap", wordBreak: "break-all", overflow: "auto" }}>{body}</pre>
                </FaqContentTitle>
            </FaqContent>
        )}
    </FaqLayout>
}

export default function Faq() {
    const GRAPHQL_END_POINT = "https://ap.laudyou.co.kr/graphql";
    const faqRef = useRef(null);
    const [searchParams] = useSearchParams()
    const [list, setList] = useState<{ id: number, type: string, title: string, body: string }[]>([])
    const [notice, setNotice] = useState<{ id: number, title: string, body: string } | null>(null)
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const limit = 5;

    useEffect(() => {
        fetch(GRAPHQL_END_POINT, {
            method: "post",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({
                query: `
        query Bbs_findArticle($size:Int,$skip:Int){
            bbs_findArticle(req:{data:{},option: {filter: "code = 'faq' and stat != 'close'" , order: " seq asc "},page:{size:$size,skip:$skip}}){
                status
                message
                total
                data{
                    id
                    type
                    title
                    body
                }
            }
            bbs_getArticle(req: {data: {code: "notice"}}) {
                status,message
                data {
                    id, title, body, 
                    stat
                }
            }
        }
                `,
                variables: {
                    size: limit,
                    skip: (page > 1) ? ((page - 1) * limit) : 0
                }
            })
        }).then(async (response) => {
            const res = await response.json();
            if (res.data && res.data.bbs_findArticle && res.data.bbs_findArticle.status == 0) {
                setList(res.data.bbs_findArticle.data);
                setTotal(res.data.bbs_findArticle.total);
            }
            if (res.data && res.data.bbs_getArticle && res.data.bbs_getArticle.status == 0) {
                const nData = res.data.bbs_getArticle.data;
                if (nData != null && nData.stat == 'open') {
                    setNotice(nData)
                }
            }
        })
    }, [page])

    useEffect(() => {
        const focus = searchParams.get("focus");
        if (focus && focus == "faq") {
            //@ts-ignore
            faqRef?.current?.scrollIntoView({ behavior: "smooth" });
        }

    }, [])

    return (
        <Layout ref={faqRef}>
            <ContentTitle title="자주묻는질문" topText="이용하면서" />
            <OutlineLayout>
                <div style={{ marginTop: "79px" }}></div>
                {notice && (
                    <DangerLayout>
                        <DangerTitle>
                            <img src="images/faq/error-ic.svg" alt="ps" />
                            <p>{notice.title}</p>
                        </DangerTitle>
                        <p style={{ paddingLeft: "25px", paddingTop: "13px", fontSize: "16px", fontWeight: 400, color: "#703535" }}>{notice.body}</p>
                    </DangerLayout>
                )}
            </OutlineLayout>
            <Space />
            {list.map(item => <FaqItem key={item.id} title={item.title} body={item.body} />)}
            <UnderLine />
            <FaqPagination limit={limit} page={page} total={total} onPage={setPage} />
        </Layout>
    );
}

const Layout = styled.div`
  margin-top: 181px;

  @media (max-width: 800px) {
    margin-top: 148px;
  }
`;

const OutlineLayout = styled.div`
  padding:0px 25px;
`;

const DangerLayout = styled.div`
  background: #FFF0F0;
  padding: 22px 20px;
  border-radius: 5px;
`;

const DangerTitle = styled.div`
  display:flex;
  flex-item:center;
  align-items: baseline;
  gap:10px;
  font-size: 16px;
  font-weight: 500;
`;
const Space = styled.div`
  height:30px;
`;
const UnderLine = styled.div`
  height:1px;
  background: #EEEEEE;
  margin-bottom: 30px;
`;
const FaqLayout = styled.div`
  border-top: solid 1px #EEEEEE;
  padding: 30px 25px;
  cursor: pointer;
  @media (max-width: 800px) {
    width:calc(100% - 50px)
  }
`;
const FaqTitle = styled.div`
  display:flex;
  justify-content: space-between;
`;

const FaqArrow = styled.div<{ open: boolean }>`
  padding: 0px 15px;
  display:inline;
  float: right;
  img {
    transform: rotate(${props => (props.open == true ? "180deg" : 0)});
  }
`;

const FaqContent = styled.div`
  margin-top: 30px;
  background: #F3F4F5;
  padding: 22px 20px;
  border-radius: 5px;
`;

const FaqContentTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #430000;
  display: flex;
  gap: 5px;
`;