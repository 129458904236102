import styled from "styled-components";
import Part from "./part";

export default function FourthBox() {
  return (
    <Layout>
      <div className="explanation">
        자녀의 휴대폰 사용 제한 시간을 설정할 수 있으며, 항상 사용 가능 앱은
        계속 사용이 가능합니다.
      </div>
      <Part text="⏰ 사용 제한 시간 구간 설정" />
      <Box>
        <img width={290} alt="img" src="/images/method/step-four/four-first.png" />
        <div className="plusImg">
          <MainText>
            선택한 요일과 시작 시간, 종료시간을 일괄적으로 제한할 수 있습니다.
          </MainText>
          <div style={{
            marginTop: "15px",
            display: "block",
            borderRadius: "5px",
            backgroundColor: "#F3F3F5",
            padding: "20px",
            color: "#555555",
            fontSize: "14px",
          }}>
            <p style={{ padding: 0, margin: 0, paddingBottom: "5px", fontWeight: "700" }}>∙ 제한 시간 구간 추가하기</p>
            <p style={{ padding: 0, margin: 0, paddingBottom: "3px", paddingLeft: "12px", lineHeight: "22px" }}>선택한 요일과 시간을 제한 시간으로 추가합니다.</p>
            <br />
            <br />
            <p style={{ padding: 0, margin: 0, paddingBottom: "5px", fontWeight: "700" }}>∙ 제한 시간 삭제</p>
            <p style={{ padding: 0, margin: 0, paddingBottom: "3px", paddingLeft: "12px", lineHeight: "22px" }}>제한 시간으로 목록에서 x 선택시, 설정한 제한 시간<br />구간이 삭제됩니다.</p>

          </div>
        </div>
      </Box>
      <Part text="📖 공부시간만큼" />
      <Box>
        <img width={290} alt="img" src="/images/method/step-four/four-second.png" />
        <div className="plusImg">
          <MainText>[시간 타일로 세부 설정하기]</MainText>
          <SubText>
            설정된 제한 시간 구간을 시간 타일 세부 설정으로 제한할 시간을
            자유롭게 선택할 수 있습니다.
          </SubText>
          <div style={{
            marginTop: "15px",
            display: "block",
            borderRadius: "5px",
            backgroundColor: "#F3F3F5",
            padding: "20px",
            color: "#555555",
            fontSize: "14px",
          }}>
            <div style={{ display: "flex", justifyContent: "start", flexDirection: "row", alignContent: "center", gap: "5px" }}>
              <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="14" rx="2" fill="#FF9A00" />
              </svg>
              <p style={{ padding: 0, margin: 0, paddingBottom: "5px", paddingTop: "2px", fontWeight: "700", display: "inline-flex" }}>
                사용 제한 시간 타일
              </p>
            </div>
            <p style={{ padding: 0, margin: 0, paddingBottom: "3px", lineHeight: "22px" }}>제한된 시간을 표시합니다. 재터치 시 해제 됩니다.</p>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "start", flexDirection: "row", alignContent: "center", gap: "5px" }}>
              <svg width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="14" rx="2" fill="#DEDEDE" />
              </svg>
              <p style={{ padding: 0, margin: 0, paddingBottom: "5px", paddingTop: "2px", fontWeight: "700" }}>
                사용 가능 시간 타일
              </p>
            </div>
            <p style={{ padding: 0, margin: 0, paddingBottom: "3px", lineHeight: "22px" }}>사용 가능 시간을 표시합니다. 재터치 시 제한됩니다.</p>

          </div>
        </div>
      </Box>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  .explanation {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    margin: 25px 0 45px;
  }
`;

const Box = styled.div`
  margin: 20px 0 50px;

  display: flex;
  align-items: start;
  gap: 20px;

  border-top: 1px solid #e3e3e3;
  padding-top: 20px;

  div {
    display: flex;
    flex-direction: column;
  }
  .plusImg {
    display: flex;
    flex-direction: column;

    gap: 0;

    img {
      margin-top: 20px;
    }
  }

  span {
    color: #444;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  @media (max-width: 800px) {
    display:block;
    .plusImg {
      width: 100%;
    }
    img {
      width:100%;
    }
  }
`;

const MainText = styled.div`
  color: #444;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
const SubText = styled.div`
  color: #444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
