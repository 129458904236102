import styled from "styled-components";
import Header from "../components/header";
import Footer from "../components/footer";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <PageLayout>
        <div>
          <Header />
          <Page>
            <PageForm>
              <Title>개인정보 처리방침</Title>
              <Text>
                <div>
                  <div>(주)파인티처('www.laudyou.com'이하 '라우드유')는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립&middot;공개합니다.</div>
                  <div>○ 이 개인정보처리방침은&nbsp;2023년&nbsp;12월&nbsp;1부터 적용됩니다.</div>
                  <br />
                  <div>제1조(개인정보의 처리 목적)</div>
                  <div>(주)파인티처('www.laudyou.com'이하 '라우드유')는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</div>
                  <br />
                  <div>1. [라우드유 부모앱] 회원가입 및 관리</div>
                  <div>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별&middot;인증, 회원자격 유지&middot;관리, 서비스 부정이용 방지, 각종 고지&middot;통지, 고충처리 목적으로 개인정보를 처리합니다.</div>
                  <br />
                  <div>2. [라우드유 부모앱] 민원사무 처리</div>
                  <div>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락&middot;통지, 처리결과 통보 목적으로 개인정보를 처리합니다.</div>
                  <br />
                  <div>3. [라우드유 부모앱] 재화 또는 서비스 제공</div>
                  <div>서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제&middot;정산을 목적으로 개인정보를 처리합니다.</div>
                  <br />
                  <div>4. [라우드유 부모앱] 마케팅 및 광고에의 활용</div>
                  <div>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</div>
                  <br /><br />
                  <div>제2조(개인정보의 처리 및 보유 기간)</div>
                  <div>①&nbsp;&lt; (주)파인티처 &gt;는 법령에 따른 개인정보 보유&middot;이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유&middot;이용기간 내에서 개인정보를 처리&middot;보유합니다.</div>
                  <div>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</div>
                  <div>&lt;[라우드유 부모앱] 회원가입 및 관리&gt;와 관련한 개인정보는 수집. 이용에 관한 동의일로부터&lt;5년&gt;까지 위 이용목적을 위하여 보유.이용됩니다.</div>
                  <div>보유근거: 캐시 및 컨텐츠 구매 계약 또는 청약철회 등에 관한 기록</div>
                  <div>관련법령: 1)소비자의 불만 또는 분쟁처리에 관한 기록: 3년2) 계약 또는 청약철회 등에 관한 기록 : 5년</div>
                  <div>예외사유: 회원 탈퇴 시 개인정보 삭제처리</div>
                  <br />
                  <div>제3조(처리하는 개인정보의 항목)①&nbsp;(주)파인티처는 다음의 개인정보 항목을 처리하고 있습니다.</div>
                  <div>&lt;[라우드유 부모앱] 회원가입 및 관리&gt;</div>
                  <div>필수항목: 이메일, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록</div>
                  <div>선택항목: [라우드유 자녀앱] 자녀의 이름 또는 별칭 (자녀 이름 및 별칭 사용 대신 특정 기호 등 구분값으로 대체 가능함.)</div>
                  <br />
                  <div>제4조(개인정보의 파기절차 및 파기방법)</div>
                  <div>① (주)파인티처 는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</div>
                  <div>법령 근거: 계약 또는 청약철회 등에 관한 기록</div>
                  <div>보존하는 개인정보 항목: 계좌정보, 거래날짜</div>
                  <div>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</div>
                  <div>파기절차&lt; (주)파인티처&gt; 는 파기 사유가 발생한 개인정보를 선정하고, (주)파인티처의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</div>
                  <div>파기방법전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다</div>
                  <br />
                  <div>제5조(정보주체와 법정대리인의 권리&middot;의무 및 그 행사방법에 관한 사항)</div>
                  <div>① 정보주체는 (주)파인티처에 대해 언제든지 개인정보 열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를 행사할 수 있습니다.</div>
                  <div>② 제1항에 따른 권리 행사는(주)파인티처에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)파인티처는 이에 대해 지체 없이 조치하겠습니다.</div>
                  <div>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 &ldquo;개인정보 처리 방법에 관한 고시(제2020-7호)&rdquo; 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</div>
                  <div>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</div>
                  <div>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</div>
                  <div>⑥ (주)파인티처는 정보주체 권리에 따른 열람의 요구, 정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</div>
                  <br />
                  <div>제6조(개인정보의 안전성 확보조치에 관한 사항)</div>
                  <div>(주)파인티처는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</div>
                  <br />
                  <div>개인정보 취급 직원의 최소화 및 교육개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</div>
                  <div>내부관리계획의 수립 및 시행개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</div>
                  <div>해킹 등에 대비한 기술적 대책(주)파인티처('라우드유')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신&middot;점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</div>
                  <div>개인정보의 암호화이용자의 개인정보 중 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</div>
                  <div>접속기록의 보관 및 위변조 방지개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.</div>
                  <div>개인정보에 대한 접근 제한개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</div>
                  <div>문서보안을 위한 잠금장치 사용개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</div>
                  <div>비인가자에 대한 출입 통제개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</div>
                  <br />
                  <div>제7조(개인정보를 자동으로 수집하는 장치의 설치&middot;운영 및 그 거부에 관한 사항)</div>
                  <div>(주)파인티처는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 &lsquo;쿠키(cookie)&rsquo;를 사용합니다.</div>
                  <div>쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.가. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.나. 쿠키의 설치&bull;운영 및 거부: 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</div>
                  <br />
                  <div>제8조(행태정보의 수집&middot;이용&middot;제공 및 거부 등에 관한 사항)</div>
                  <div>① &lt;개인정보처리자&gt;는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 컨텐츠 등을 제공하기 위하여 행태정보를 수집&middot;이용하고 있습니다. 단, 맞춤형 광고를 제공하지 않습니다.</div>
                  <div>② &lt;개인정보처리자&gt;는 다음과 같이 행태정보를 수집합니다.</div>
                  <table style={{ border: "1px solid black", textAlign: "center" }}>
                    <tr style={{ border: "1px solid black" }}>
                      <td style={{ border: "1px solid black" }}>수집하는 행태정보의 항목</td>
                      <td style={{ border: "1px solid black" }}>행태정보 수집 방법</td>
                      <td style={{ border: "1px solid black" }}>행태정보 수집 목적</td>
                      <td style={{ border: "1px solid black" }}>보유-이용기간 및 이후 정보처리 방법</td>
                    </tr>
                    <tr style={{ border: "1px solid black" }}>
                      <td style={{ border: "1px solid black" }}>이용자의 앱 서비스 방문이력, 구매이력, 사용이력</td>
                      <td style={{ border: "1px solid black" }}>이용자의 앱 방문 및 앱 사용 시 자동 수집</td>
                      <td style={{ border: "1px solid black" }}>이용자 관심에 맞는 컨텐츠 추천 및 이용자 사용 결과에 따른 컨텐츠 개인별 노출을 위한 수집</td>
                      <td style={{ border: "1px solid black" }}>수집일로부터 90일 이후 파기</td>
                    </tr>
                  </table>
                  <br />
                  <div>③ &lt;개인정보처리자&gt;는 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주 이용자로 하는 온라인 서비스로부터 맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형 광고를 제공하지 않습니다.</div>
                  <div>④ 정보주체는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 컨텐츠를 일괄적으로 차단&middot;허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.</div>
                  <div>‣ 웹브라우저를 통한 맞춤형 컨텐츠 차단/허용</div>
                  <div>(1) 인터넷 익스플로러(Windows 10용 Internet Explorer 11)</div>
                  <div>- Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택</div>
                  <div>- 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택</div>
                  <div>(2) Microsoft Edge</div>
                  <div>- Edge에서 오른쪽 상단 &lsquo;&hellip;&rsquo; 표시를 클릭한 후, 설정을 클릭합니다.</div>
                  <div>- 설정 페이지 좌측의 &lsquo;개인정보, 검색 및 서비스&rsquo;를 클릭 후 「추적방지」 섹션에서 &lsquo;추적방지&rsquo; 여부 및 수준을 선택합니다.</div>
                  <div>- &lsquo;InPrivate를 검색할 때 항상 ""엄격"" 추적 방지 사용&rsquo; 여부를 선택합니다.</div>
                  <div>- 아래 「개인정보」 섹션에서 &lsquo;추적 안함 요청보내기&rsquo; 여부를 선택합니다.</div>
                  <div>(3) 크롬 브라우저</div>
                  <div>- Chrome에서 오른쪽 상단 &lsquo;⋮&rsquo; 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.</div>
                  <div>- 설정 페이지 하단에 &lsquo;고급 설정 표시&rsquo;를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.</div>
                  <div>- 쿠키 섹션에서 &lsquo;타사 쿠키 및 사이트 데이터 차단&rsquo;의 체크박스를 선택합니다.</div>
                  <div>⑤ 정보주체는 아래의 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.</div>
                  <div>‣ 개인정보 보호 담당부서</div>
                  <div>부서명 : 고객관리팀</div>
                  <div>담당자 : 안관희</div>
                  <div>연락처 : &lt;전화번호&gt;070-4632-3343, &lt;이메일&gt; laudyou1@gmail.com, &lt;팩스번호&gt;070-4632-3349</div>
                  <br />
                  <div>제9조(추가적인 이용&middot;제공 판단기준)</div>
                  <div>(주)파인티처는 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용&middot;제공할 수 있습니다.이에 따라 (주)파인티처가 정보주체의 동의 없이 추가적인 이용&middot;제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.</div>
                  <br />
                  <div>▶ 개인정보를 추가적으로 이용&middot;제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부</div>
                  <div>▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용&middot;제공에 대한 예측 가능성이 있는지 여부</div>
                  <div>▶ 개인정보의 추가적인 이용&middot;제공이 정보주체의 이익을 부당하게 침해하는지 여부</div>
                  <div>▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</div>
                  <div>※ 추가적인 이용&middot;제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성&middot;공개함</div>
                  <br />
                  <div>제10조 (개인정보 보호책임자에 관한 사항)</div>
                  <div>①&nbsp;(주)파인티처는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</div>
                  <div>▶ 개인정보 보호책임자</div>
                  <div>성명: 서명원</div>
                  <div>직책: 실장</div>
                  <div>직급: 부장</div>
                  <div>연락처: &lt;전화번호&gt;070-4632-3343, &lt;이메일&gt; fineteacher1@gmail.com, &lt;팩스번호&gt;070-4632-3349</div>
                  <div>※ 개인정보 보호 담당부서로 연결됩니다.</div>
                  <div>▶ 개인정보 보호 담당부서</div>
                  <div>부서명: 고객관리팀</div>
                  <div>담당자: 안관희</div>
                  <div>연락처: &lt;전화번호&gt;070-4632-3343, &lt;이메일&gt; laudyou1@gmail.com, &lt;팩스번호&gt;070-4632-3349</div>
                  <br />
                  <div>② 정보주체께서는 (주)파인티처의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)파인티처는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</div>
                  <br />
                  <div>제11조(개인정보의 열람청구를 접수&middot;처리하는 부서)정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.&lt; (주)파인티처 &gt;는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</div>
                  <div>▶ 개인정보 열람청구 접수&middot;처리 부서</div>
                  <div>부서명: 고객관리팀</div>
                  <div>담당자: 안관희</div>
                  <div>연락처: &lt;전화번호&gt;070-4632-3343, &lt;이메일&gt; laudyou1@gmail.com, &lt;팩스번호&gt;070-4632-3349</div>
                  <br />
                  <div>제12조(정보주체의 권익침해에 대한 구제방법)</div>
                  <div>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.1. 개인정보분쟁조정위원회: (국번없이) 1833-6972 (www.kopico.go.kr)2. 개인정보침해신고센터: (국번없이) 118 (privacy.kisa.or.kr)3. 대검찰청: (국번없이) 1301 (www.spo.go.kr)4. 경찰청: (국번없이) 182 (ecrm.cyber.go.kr)「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정&middot;삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</div>
                </div>
              </Text>
            </PageForm>
          </Page>
        </div>
      </PageLayout>
      <Footer />
    </Layout>
  );
}

const Layout = styled.div`
  height: 100%;

  background: #f0f0f0;
`;

const PageLayout = styled.div`
  height: 100%;
`;

const Page = styled.div`
  width: 1000px;

  padding: 30px;
  margin: 0 auto;

  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    width:100%;
    padding:0px;
  }
`;
const PageForm = styled.div`
  padding: 80px 100px 394px;
  background: white;

  @media (max-width: 800px) {
    padding: 25px;
  }
`;

const Title = styled.div`
  width: 800px;

  color: #333;
  font-size: 38px;
  font-weight: 600;
  line-height: 40px;

  padding-bottom: 40px;
  border-bottom: 1px solid #b1b1b1;
  margin-bottom: 40px;

  @media (max-width: 800px) {
    width:100%;
  }
`;
const Text = styled.div`
  width: 800px;

  color: #444;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;

  padding-bottom: 80px;
  border-bottom: 1px solid #b1b1b1;

  text-align: left;

  @media (max-width: 800px) {
    width:100%;
  }
`;
