import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landing";
import UseTerms from "./pages/terms";
import PrivacyPolicy from "./pages/privacy-policy";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/useTerms" element={<UseTerms />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
