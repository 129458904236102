import styled from "styled-components";

export default function Assignment() {
  const boxProp = [
    {
      img: "/images/assignment-box/first.png",
      type: "초등수학",
    },
    {
      img: "/images/assignment-box/second.png",
      type: "초등영어",
    },
    {
      img: "/images/assignment-box/third.png",
      type: "사자성어",
    },
  ];

  return (
    <Layout>
      <TextForm>
        <h3>
          <div>
            <b>학습 과제</b>를
          </div>
          <div className="secondLine">선택해서 공부하세요.</div>
        </h3>
        <div className="subText">
          앱을 통해 해당 과목의 학습과제를 둘러보고
          <br />
          체험해보실 수 있습니다.
        </div>
      </TextForm>
      <BoxForm>
        {boxProp.map((e,index) => {
          return (
            <Box key={index}>
              <img alt="assignmentType" src={e.img} />
              <div>{e.type}</div>
            </Box>
          );
        })}
      </BoxForm>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 292px;

  @media (max-width: 800px) {
    flex-direction: column;

    margin: 172px auto 0;
  }
`;

const TextForm = styled.div`
  margin-top: 4px;

  h3 {
    color: #555;
    font-size: 58px;
    font-weight: 300;
    line-height: 58px;

    b {
      color: #4bc500;
      font-weight: 600;
    }
    .secondLine {
      font-size: 48px;
    }
  }

  .subText {
    color: #818865;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;

    margin-top: 11px;
  }

  @media (max-width: 800px) {
    margin: 0;

    h3 {
      font-size: 30px;
      line-height: 38px;

      .secondLine {
        font-size: 30px;
        line-height: 38px;
      }
    }

    .subText {
      margin-top: 10px;
    }
  }
`;

const BoxForm = styled.div`
  display: flex;

  gap: 20px;

  filter: drop-shadow(0px 0px 10px #cef0a4);

  @media (max-width: 800px) {
    gap: 12.5px;

    margin-top: 35px;
  }
`;
const Box = styled.div`
  width: 160px;
  height: 160px;

  border-radius: 20px;

  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 2.3px;

  img {
    margin-top: 26px;
  }
  div {
    color: #333;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }

  @media (max-width: 800px) {
    width: 100px;
    height: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1.4px;

    img {
      width: 54px;

      margin-top: 16px;
    }
  }
`;
