import styled from "styled-components";

interface IStepProp {
  stepNumber: string;
  title: string;
  text?: string;
}

export default function Step({ stepNumber, title, text }: IStepProp) {
  return (
    <>
      <StepNumber>STEP {stepNumber}</StepNumber>
      <Title>{title}</Title>
      {text && <Text>{text}</Text>}
    </>
  );
}

const StepNumber = styled.div`
  color: #76d300;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;

  letter-spacing: -0.4px;
`;

const Title = styled.div`
  color: #555;
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;

  margin-top: 18px;
`;

const Text = styled.pre`
  color: #444;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  margin-top: 10px;
  @media (max-width: 800px) {
    overflow: auto;
    white-space: pre-wrap;
  }
`;
