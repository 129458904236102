import styled from "styled-components";

export default function Downloads() {
  return (
    <Layout>
      <DownloadForm>
        <div className="title">부모님용 자녀관리 앱 다운로드 받기</div>
        <div className="subTitle">iOS, 안드로이드 사용 가능</div>
        <div className="buttonForm">
          <button
            type="button"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.fineteacher.loudyou.parents",
                "_blank"
              )
            }
          >
            <img width={182} alt="AppStoreDownload" src="/images/download/googleplay.png" />
          </button>
          {/* <div className="notice">
            <img alt="exclamation" src="/images/exclamation.svg" />
            <div>
              iOS용 부모용앱
              <br />
              심사 진행 중
            </div>
          </div> */}
          <button
            type="button"
            onClick={() =>
              window.open(
                "https://apps.apple.com/kr/app/laud-you-%EB%B6%80%EB%AA%A8%EC%9A%A9/id6474279888",
                "_blank"
              )
            }
          >
            <img width={175} height={54} alt="GooglePlayDownload" src="/images/download/appstore.png" />
          </button>
        </div>
      </DownloadForm>
      <DownloadForm>
        <div className="title">자녀용 공부앱 다운로드 받기</div>
        <div className="subTitle">안드로이드 사용 가능</div>
        <div className="buttonForm">
          <button
            type="button"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.laudyou",
                "_blank"
              )
            }
          >
            <img width={182} alt="AppStoreDownload" src="/images/download/googleplay.png" />
          </button>
          <div className="notice">
            <img alt="exclamation" src="/images/exclamation.svg" />
            <div>
              iOS용 공부앱
              <br />
              업데이트 준비중
            </div>
          </div>
        </div>
      </DownloadForm>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const DownloadForm = styled.div`
  width: 495px;
  height: 180px;

  border-radius: 10px;
  border: 1px solid #d9dfd6;
  background: #f8faf7;

  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #333;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;

    margin-top: 32px;
  }

  .subTitle {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    margin-top: 10px;
  }

  .buttonForm {
    display: flex;
    gap: 14px;

    margin-top: 20px;

    button {
      cursor: pointer;
      height: 54px;

      padding: 0;
      border: 0;

      border-radius: 8px;
    }

    button:first-child {
      width: 182px;
    }
    button:nth-child(2) {
      width: 175px;
    }

    .notice {
      cursor: default;
      width: 175px;
      height: 52px;

      background: white;
      border: 1px solid #000;
      border-radius: 8px;

      padding: 0;

      display: flex;
      align-items: center;
      gap: 10px;

      color: #222;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;

      img {
        margin-left: 15px;
      }
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
    min-height: 250px;

    .buttonForm {
      flex-direction: column;
      align-items: center;
    }
  }
`;
