import styled from "styled-components";

interface IPart {
  img?: string;
  text: string;
}

export default function Part({ img, text }: IPart) {
  return (
    <Layout>
      {img && <img alt="img" src={img} />}
      <span>{text}</span>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  span {
    color: #555;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
  }
`;
