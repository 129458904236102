import styled from "styled-components";
import ContentTitle from "./content-title";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";

export default function Reason() {
  const cardDetail = [
    {
      num: "01",
      title: "자유로운 학습 설정",
      text: "공부한 시간만큼 또는 공부한 문제 수만큼 휴대폰 사용 시간을 설정할 수 있으며, 공부할 수 있는 학습 과정을 선택할 수 있습니다.",
      img: "/images/reason/one.png",
    },
    {
      num: "02",
      title: "휴대폰 사용시간 관리",
      text: "사용 제한 시간관리로 사용을 제한하고 사용 가능 시간에는 공부한 만큼 휴대폰 사용이 가능합니다.",
      img: "/images/reason/two.png",
    },
    {
      num: "03",
      title: "자녀 학습 과정 선택",
      text: "자녀가 공부하기를 원하는 학습 과정을 선택하여 공부하도록 설정할 수 있습니다.",
      img: "/images/reason/three.png",
    },
    {
      num: "04",
      title: "부모 관리 앱",
      text: "부모 관리 앱으로 자녀의 휴대폰 사용내역과 공부한 내역을 알 수 있고 학습 과정을 관리할 수 있습니다.",
      img: "/images/reason/four.png",
    },
    {
      num: "05",
      title: "편리한 학습 과정 구매",
      text: "자녀에게 필요한 학습 과정을 바로 구매하여 바로 학습할 수 있도록 제공합니다.",
      img: "/images/reason/five.png",
    },
    {
      num: "06",
      title: "칭찬하고 보상주기",
      text: "열심히 공부하거나 착한 일을 한 자녀에게 칭찬 스티커를 주고 목표만큼 모을 경우 보상해 줄 수 있습니다.",
      img: "/images/reason/six.png",
    },
  ];
  const layoutRef = useRef(null);
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const focus = searchParams.get("focus");
    if (focus && focus == "reason") {
      //@ts-ignore
      layoutRef?.current?.scrollIntoView({ behavior: "smooth" });
    }

  }, [])

  return (
    <Layout ref={layoutRef}>
      <ContentTitle title="6가지이유" topText="사용해야하는" />
      <CardLayout>
        {cardDetail.map((e,index) => {
          return (
            <Card key={index}>
              <div className="cardForm">
                <div className="letter">
                  <Number>{e.num}</Number>
                  <Title>{e.title}</Title>
                  <Text>{e.text}</Text>
                </div>
                <img alt="img" src={e.img} />
              </div>
            </Card>
          );
        })}
      </CardLayout>
    </Layout>
  );
}

const Layout = styled.div`
  margin-top: 181px;

  @media (max-width: 800px) {
    margin-top: 148px;
  }
`;

const CardLayout = styled.div`
  margin-top: 79px;

  display: grid;
  grid-template-columns: 310px 310px 310px;
  grid-gap: 35px;

  > :hover {
    outline: 3px solid #76d300;
  }

  @media (max-width: 800px) {
    margin-top: 50px;
    display: flex;
    flex-flow: column;
  }
`;

const Card = styled.div`
  width: 310px;

  border-radius: 20px;
  background: #fff;

  box-shadow: 0px 0px 30px 0px #d3e1c0;

  .cardForm {
    display: flex;
    flex-direction: column;
    align-items: start;

    color: #555;

    padding: 40px 30px 30px 30px;

    img {
      width: 250px;
      height: auto;

      margin-top: 20px;
    }
  }

  .letter {
    width: 250px;
    height: 160px;
  }
  @media (max-width: 800px) {
    width: calc(100% - 64px);
    margin: 0 32px;
    .letter {
      width:100%;
    }

    .cardForm {
      display:block;
      img {
        width: 100%;
        height: auto;
      }
    }

  }
`;

const Number = styled.div`
  color: #76d300;
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;

  margin: 10px 0 8px;
`;
const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  margin-top: 8px;
`;
