import styled from "styled-components";
import Part from "./part";

export default function FirstBox() {
  const contents = [
    {
      partImg: "/images/method/step-four/timer.svg",
      partText: "항상 사용 가능 앱",
      boxImg: "/images/method/step-four/box_timer.png",
      boxText:
        "항상 사용 가능 앱은 공부 여부와 상관없이 자녀가 항상 사용할 수 있는 앱 입니다. 항상 사용해도 괜찮은 앱들을 항상 사용 가능 앱으로 등록해 주세요.",
    },
    {
      partImg: "/images/method/step-four/phone_lock.svg",
      partText: "공부 후 사용 가능 앱",
      boxImg: "/images/method/step-four/box_phone_lock.png",
      boxText:
        "공부 후 사용 가능한 앱은 공부로 획득한 시간 동안 사용 가능한 앱입니다. 공부한 보상으로 자녀가 사용할 앱들을 공부 후 사용 가능 앱으로 등록해 주세요.",
    },
    {
      partImg: "/images/method/step-four/phone_erase.svg",
      partText: "항상 사용 제한 앱",
      boxImg: "/images/method/step-four/box_phone_erase.png",
      boxText:
        "항상 사용 제한 앱은 자녀가 사용하기를 원하지 않는 앱을 등록하면 공부를 하더라도 사용이 불가한 앱 목록입니다.",
      caution:
        "단, PIN 번호를 사용하거나 라우드유 앱의 제한을 풀어서 휴대폰 사용 시에는 접근이 가능합니다.",
    },
  ];

  return (
    <Layout>
      <div className="explanation">
        앱 관리 설정에서 자녀의 앱 사용을 제한하거나, 공부 후 사용할 앱을 설정할
        수 있습니다.
      </div>
      {contents.map((e, index) => {
        return (
          <div key={index}>
            <Part img={e.partImg} text={e.partText} />
            <Box>
              <img width={290} alt="img" src={e.boxImg} />
              <div>
                <span>{e.boxText}</span>
                {e.caution && <span>{e.caution}</span>}
              </div>
            </Box>
          </div>
        );
      })}
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  .explanation {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    margin: 25px 0 45px;
  }
`;

const Box = styled.div`
  margin: 20px 0 50px;
  padding: 20px 23px 20px 18px;

  border-radius: 15px;
  border: 1px solid #e3e3e3;

  background: #fff;

  display: flex;
  align-items: start;
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;

    gap: 29px;
  }
  span {
    color: #444;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  @media (max-width: 800px) {
    display:block;
    img {
      width:100%;
    }
  }
`;
