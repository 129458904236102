import styled from "styled-components";
import Part from "./part";

export default function ThirdBox() {
  return (
    <Layout>
      <div className="explanation">
        라우드유 자녀앱을 통해 자녀의 학습 방식을 설정할 수 있습니다.
      </div>
      <Part text="📖 문제당 시간" />
      <Box>
        <img width={290} alt="img" src="/images/method/step-four/three-first.png" />
        <div>
          <MainText>
            공부 방식을 문제당 시간으로 설정 시, 공부한 문제 수 만큼 휴대폰
            사용시간이 제공됩니다.
          </MainText>
          <SubText>
            1문제 풀이당 - 1분 추가 제공으로 선택할 경우, 10문제를 풀면 휴대폰
            사용 시간 10분이 제공됩니다.
          </SubText>
        </div>
      </Box>
      <Part text="📖 시간내 달성" />
      <Box>
        <img width={290} alt="img" src="/images/method/step-four/three-second.png" />
        <div className="plusImg">
          <MainText>
            제한된 시간 이내 목표한 문제 수를 푼 경우에 설정된 휴대폰 사용시간이
            제공됩니다.
          </MainText>
          <SubText>
            1분내 - 10문제 풀이 시 - 30분 제공으로 설정 시, 1분 이내에 10문제
            이상 풀어야 시간이 제공됩니다.
          </SubText>
          <div style={{
            marginTop: "15px",
            display: "block",
            borderRadius: "5px",
            backgroundColor: "#F3F3F5",
            padding: "20px",
            color: "#555555",
            fontSize: "14px",
          }}>
            <p style={{ padding: 0, margin: 0, paddingBottom: "13px", fontWeight: "700" }}>제공시간 누적 여부 :</p>
            <p style={{ padding: 0, margin: 0, paddingBottom: "3px",lineHeight:"22px" }}>제공 시간 누적 여부 선택 시 목표 달성한 횟수 만큼 휴대폰 시간이 제공되며<br/>미 선택 시, 목표 달성으로 받은 휴대폰 사용 시간을 사용한 후<br/>다시 목표를 달성해야 시간이 부여됩니다.</p>
            
          </div>
        </div>
      </Box>
      <Part text="📖 공부시간만큼" />
      <Box>
        <img width={290} alt="img" src="/images/method/step-four/three-third.png" />
        <div>
          <MainText>공부한 시간만큼 휴대폰 사용 시간이 제공됩니다.</MainText>
          <SubText>
            1시간 공부 시 - 1시간 제공으로 설정한 경우, 한번에 1시간 이상
            공부해야 휴대폰 사용시간이 제공되며 50분을 공부한 경우 보상이
            제공되지 않습니다.
          </SubText>
        </div>
      </Box>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  .explanation {
    color: #666;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    margin: 25px 0 45px;
  }
`;

const Box = styled.div`
  margin: 20px 0 50px;

  display: flex;
  align-items: start;
  gap: 20px;

  border-top: 1px solid #e3e3e3;
  padding-top: 20px;

  div {
    display: flex;
    flex-direction: column;
  }
  .plusImg {
    display: flex;
    flex-direction: column;

    gap: 0;

    img {
      margin-top: 15px;
    }
  }

  span {
    color: #444;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  @media (max-width: 800px) {
    display:block;
    .plusImg {
      width: 100%;
    }
    img {
      width:100%;
    }
  }
`;

const MainText = styled.div`
  color: #444;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;
const SubText = styled.div`
  color: #444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  margin-top: 14px;
`;
