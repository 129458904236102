import { isDesktop, isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";


export default function Header() {
  const navigate = useNavigate();
  return (
    <>
      <Layout>
        <Form>
          <LeftForm>
            {isMobile && (
              <div style={{ position: "absolute", left: "15px", top: "14px", width: "25px", height: "25px", padding: "10px", cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 13L1 7L7 1" stroke="#25282B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            )}
            <img alt="logo" src="/images/laudyou-logo.svg" />
            <span>LAUD YOU</span>
          </LeftForm>
          {isDesktop && (
            <RightForm>
              <Link to={"/useTerms"}
                style={{
                  textDecorationLine: "none",
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "#444444",
                  textDecorationColor: "#444444",
                }}
              >
                이용약관
              </Link>
              <Link to={"/privacyPolicy"}
                style={{
                  textDecorationLine: "none",
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "#444444",
                  textDecorationColor: "#444444",
                }}
              >
                개인정보처리방침
              </Link>
            </RightForm>
          )}
        </Form>
      </Layout>
      {isMobile && (
        <div>
          <div style={{
            display: "flex",
            padding: "18px 30px",
            gap: "14px"
          }}>
            <Link to={"/useTerms"}
              style={{
                textDecorationLine: "none",
                fontSize: "14px",
                fontWeight: "500",
                color: "#444444",
                textDecorationColor: "#444444",
              }}
            >
              이용약관
            </Link>
            <Link to={"/privacyPolicy"}
              style={{
                textDecorationLine: "none",
                fontSize: "14px",
                fontWeight: "500",
                color: "#444444",
                textDecorationColor: "#444444",
              }}
            >
              개인정보처리방침
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

const Layout = styled.div`
  background-color: white;

  border-bottom: 1px solid #d2d2d2;
`;
const Form = styled.div`
  width: 1000px;
  margin: 0 auto;

  display: flex;
  align-items: end;
  justify-content: space-between;

  @media (max-width: 800px) {
    width:100%;
  }
`;

const LeftForm = styled.div`
  display: flex;
  padding-top: 70px;
  padding-bottom: 20px;

  span {
    font-size: 30px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: -0.3px;

    display: flex;
    align-items: center;

    margin-left: 15px;
  }

  img {
    width: 70px;
  }
  @media (max-width: 800px) {
    width:100%;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    
    img {
      width: 40px;
    }
    span {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
    }
  }
`;

const RightForm = styled.div`
  display: flex;
  gap: 40px;

  margin-bottom: 35px;

  button {
    cursor: pointer;

    padding: 0;
    border: 0;
    border-radius: 8px;

    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;

    background: none;
  }

  span {
    color: #444;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
`;
