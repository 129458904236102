import styled from "styled-components";
import Header from "../components/header";
import Footer from "../components/footer";

export default function UseTerms() {
  return (
    <Layout>
      <PageLayout>
        <div>
          <Header/>
          <Page>
            <PageForm>
              <Title>라우드유 이용약관</Title>
              <Text>
                <p>
                  제1장 총칙
                  <br />
                  &nbsp;
                  <br />
                  제1조 (목적)
                  <br />
                  주식회사 파인티처 (이하 &ldquo;회사&rdquo;)가 온라인으로
                  제공하는 교육서비스(이하 &ldquo;라우드유 서비스&rdquo; 또는
                  &ldquo;서비스&rdquo;라 함)의 이용과 관련하여 회사와 회원 간의
                  권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로
                  합니다.
                  <br />
                  &nbsp;
                  <br />
                  제2조 (용어의 정의)
                  <br />① 이 약관에서 사용하는 정의는 다음과 같습니다.
                  <br />
                  &nbsp;1. &ldquo;회사&rdquo;라 함은 온라인을 통하여
                  교육서비스를 제공하는 사업자를 의미합니다.
                  <br />
                  &nbsp;2. &ldquo;회원&rdquo;이라 함은 본 약관에 동의하고
                  교육서비스 이용 자격을 부여받은 자를 의미합니다.
                  <br />
                  &nbsp;3. &ldquo;교육서비스&rdquo;라 함은 회사가 회원에게
                  온라인으로 제공하는 교육, 휴대폰 관리 및 이에 부수된 제반
                  서비스를 의미합니다.
                  <br />
                  &nbsp;4. &ldquo;계정&rdquo;이라 함은 회원의 식별과 교육서비스
                  이용을 위하여 회원이 SNS 로그인을 통한 회원가입을 통해
                  SNS업체로부터 받은 ID를 의미합니다.
                  <br />
                  &nbsp;5. &ldquo;계정정보&ldquo;라 함은 회원의 계정, 성명 등
                  회원이 SNS 로그인을 통해 회사에 제공한 일반정보 및
                  서비스이용정보, 이용요금 결제상태 등 생성정보를 통칭합니다.
                  <br />
                  &nbsp;6. &ldquo;학생 이름&rdquo; 또는 &ldquo;자녀
                  이름&rdquo;이라 함은 회원의 자녀가 교육서비스의 이용을 위하여
                  자녀 앱에서 등록한 이름 또는 닉네임을 의미합니다.
                  <br />
                  &nbsp;7. &ldquo;캐시&rdquo;라 함은 교육서비스를 이용 또는 학습
                  컨텐츠를 구매하기 위해 사용되는 가상의 데이터로서 회원이
                  대금을 지급하고 구입하는 것을 말합니다.
                  <br />
                  &nbsp;8. &ldquo;추천코드&rdquo; 또는 &ldquo;프로모션
                  코드&rdquo;라 함은 회원별로 발급받는 코드로 지인에게
                  교육서비스를 추천하고 추천 받은 사람이 교육서비스를 가입 또는
                  학습 컨텐츠를 구매 시 입력하고 가입 또는 학습 컨텐츠를 구매할
                  경우 보상을 받을 수 있는 리워드 프로그램의 코드를 말합니다.
                  추천 시 추천한 회원 또한 보상을 받을 수 있습니다
                  <br />
                  &nbsp;9. &ldquo;게시물&rdquo;이라 함은 회원이 서비스를
                  이용함에 있어 회원이 게시한 문자, 문서, 그림, 음성, 영상 또는
                  이들의 조합으로 이루어진 모든 정보를 말합니다.
                  <br />② 이 약관에서 사용하는 용어의 정의는 제1항 각호에서
                  정하는 것을 제외하고는 관계법령 및 기타 일반적인 상관례에
                  의합니다.
                  <br />
                  &nbsp;
                  <br />
                  제3조 (약관의 명시와 개정)
                  <br />① 회사는 이 약관의 내용을 회원이 알 수 있도록 서비스
                  초기 화면이나 교육서비스 홈페이지(www.laudyou.com)에
                  게시하거나 연결화면을 제공하는 방법으로 회원에게 공지합니다.
                  <br />② 회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및
                  응답을 할 수 있도록 조치를 취합니다.
                  <br />③ 회사는 교육서비스를 이용하고자 하는 자(이하
                  &ldquo;이용자&rdquo;라 한다)가 약관의 내용을 쉽게 알 수 있도록
                  작성하고 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중
                  청약철회, 과오납금의 환급, 계약 해제ㆍ해지, 회사의 면책사항 및
                  회원에 대한 피해보상 등과 같은 중요한 내용을 회원이 쉽게
                  이해할 수 있도록 굵은 글씨 등으로 처리하거나 별도의 연결화면
                  또는 팝업화면 등을 제공하고 이용자의 동의를 얻도록 합니다.
                  <br />④ 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」,
                  「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호
                  등에 관한 법률」, 「콘텐츠산업진흥법」 등 관련 법령에 위배하지
                  않는 범위에서 이 약관을 개정할 수 있습니다.
                  <br />⑤ 회사가 약관을 개정할 경우에는 적용일자 및 개정내용,
                  개정사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전부터
                  그 적용일자 경과 후 상당한 기간이 경과할 때까지 초기화면 또는
                  초기화면과의 연결화면을 통해 공지하고 회원에게 불리하거나
                  중대한 사항의 변경은 그 적용 30일 이전까지 공지하고 제25조
                  제1항의 방법으로 회원에게 통지합니다.
                  <br />
                  &nbsp;⑥ 회사가 약관을 개정할 경우에는 개정약관 공지 후
                  개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 개정약관
                  공지 시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한
                  것으로 간주하겠다는 내용도 함께 공지한 경우에는 회원이 약관
                  시행일까지 거부의사를 표시하지 않는다면 개정약관에 동의한
                  것으로 간주할 수 있습니다.
                  <br />⑦ 회원이 개정약관의 적용에 동의하지 않는 경우 회사 또는
                  회원은 교육서비스 이용계약을 해지할 수 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제4조 (약관 외 준칙)
                  <br />이 약관에서 정하지 아니한 사항과 이 약관의 해석에
                  관하여는 「전자상거래 등에서의 소비자보호에 관한
                  법률」,「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및
                  정보보호 등에 관한 법률」,「콘텐츠산업진흥법」 등 관련 법령에
                  따릅니다.
                  <br />
                  &nbsp;
                  <br />
                  제2장 이용계약의 체결
                  <br />
                  &nbsp;
                  <br />
                  제5조 (이용신청 및 방법)
                  <br />① 회사가 제공하는 교육서비스를 이용하고자 하는 자는 이
                  약관의 내용에 대하여 동의를 한 다음 SNS 계정 (카카오 계정,
                  네이버 계정, 구글 계정, 애플 계정 중 하나)으로 로그인하는
                  방법으로 이용신청을 하여야 하고, 회사가 그 신청에 대해서
                  승낙함으로써 이용계약이 체결됩니다.
                  <br />② 이용자는 교육서비스에 가입하기에 위해서는 SNS 계정
                  (카카오 계정, 네이버 계정, 구글 계정, 애플 계정 중 하나)이
                  필요합니다.
                  <br />③ 이용자는 제1항의 이용 신청 시 본인의 SNS 계정을
                  사용하여야 합니다. 타인의 SNS 계정을 사용한 경우 이 약관에
                  의한 회원의 권리를 주장할 수 없고, 회사는 환급없이 이용계약을
                  취소하거나 해지할 수 있습니다.
                  <br />④ 본 교육서비스는 자녀를 둔 학부모님를 대상으로 제공하는
                  서비스로 청소년(18세 미만의 자로서
                  「초&middot;중등교육법」제2조의 규정에 의한 고등학교에 재학
                  중인 학생을 포함한다)이 직접 이용신청을 할 수 없고, 학부모가
                  가입을 하고 청소년은 학부모 계정의 관리 기준으로 이름 또는
                  닉네임만 등록하여 교육서비스를 사용할 수 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제6조 (이용신청의 승낙과 제한)
                  <br />① 회사는 회사가 이용자에게 요구하는 정보에 대해 이용자가
                  본인의 SNS 계정을 사용하여 이용신청을 한 경우에 상당한 이유가
                  없는 한 이용신청을 승낙합니다.
                  <br />② 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에
                  대해서는 승낙을 하지 않을 수 있습니다.
                  <br />
                  &nbsp;1. 제5조에 위반하여 이용신청을 하는 경우
                  <br />
                  &nbsp;2. 리워드 프로그램을 악용하여 교육서비스를 이용하는 경우
                  <br />
                  &nbsp;3. 타인의 SNS 계정을 이용하여 가입한 경우
                  <br />
                  &nbsp;4. 최근 3개월 내 이용제한 기록이 있는 이용자가
                  이용신청을 하는 경우
                  <br />
                  &nbsp;5. 제3자의 신용카드, 유/무선 전화, 은행 계좌 등을
                  무단으로 이용 또는 도용하여 서비스 이용요금을 결제하는 경우
                  <br />
                  &nbsp;6. 대한민국 이외의 국가 중 회사에서 아직 서비스를 제공할
                  것으로 결정하지 않은 국가에서 서비스를 이용하는 경우로 회사가
                  해외 서비스 업체와 체결한 계약이나 특정 국가에서 접속하는
                  회원에 대한 서비스 제공과 관련하여 서비스제공을 제한할 필요가
                  있는 경우
                  <br />
                  &nbsp;7. 「정보통신망 이용촉진 &nbsp;및 정보보호 등에 관한
                  법률」 및 그 밖의 관계 법령에서 금지하는 위법행위를 할
                  목적으로 이용신청을 하는 경우
                  <br />
                  &nbsp;8. 그 밖에 1호 내지 7호에 준하는 사유로서 승낙이
                  부적절하다고 판단되는 경우
                  <br />③ 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 그
                  사유가 해소될 때까지 승낙을 유보할 수 있습니다.
                  <br />
                  &nbsp;1. 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우
                  <br />
                  &nbsp;2. 서비스 상의 장애 또는 서비스 이용요금 결제수단의
                  장애가 발생한 경우
                  <br />
                  &nbsp;3. 그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이
                  곤란한 경우
                  <br />
                  &nbsp;
                  <br />
                  제7조 (회원 정보의 제공 및 변경)
                  <br />① 회원은 이 약관에 의하여 회사에 정보를 제공하여야 하는
                  경우에는 진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해
                  발생한 불이익에 대해서는 보호받지 못합니다.
                  <br />② 회원은 개인정보관리화면을 통하여 언제든지 자신의
                  개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를
                  위해 필요한 회원가입 시 사용한 SNS계정 정보는 수정이
                  불가능합니다.
                  <br />③ 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우
                  온라인으로 수정을 하거나 기타 방법으로 회사에 대하여 그
                  변경사항을 알려야 합니다.
                  <br />④ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에
                  대하여 회사는 책임을 지지 않습니다.
                  <br />
                  &nbsp;
                  <br />
                  제8조 (개인정보의 보호 및 관리)
                  <br />① 회사는 관계 법령이 정하는 바에 따라 계정정보를 포함한
                  회원의 개인정보를 보호하기 위해 노력합니다. 회원 개인정보의
                  보호 및 사용에 대해서는 관계법령 및 회사가 별도로 고지하는
                  개인정보취급방침이 적용됩니다.
                  <br />② 서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서
                  홈페이지 및 교육서비스별 웹사이트에서 단순히 링크된 제3자
                  제공의 서비스에 대하여는 회사의 개인정보취급방침이 적용되지
                  않습니다.
                  <br />③ 회사는 회원의 귀책사유로 인하여 노출된 회원의
                  계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지
                  않습니다.
                  <br />
                  &nbsp;
                  <br />
                  제3장 계약 당사자의 의무
                  <br />
                  &nbsp;
                  <br />
                  제9조 (회사의 의무)
                  <br />① 회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의
                  행사와 의무의 이행을 신의에 따라 성실하게 합니다.
                  <br />② 회사는 회원이 안전하게 서비스를 이용할 수 있도록
                  개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며
                  개인정보취급방침을 공시하고 준수합니다. 회사는 이 약관 및
                  개인정보취급방침에서 정한 경우를 제외하고는 회원의 개인정보가
                  제3자에게 공개 또는 제공되지 않도록 합니다.
                  <br />③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여
                  서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이
                  멸실된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이
                  불가능한 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이
                  이를 수리 또는 복구하도록 최선의 노력을 다합니다.
                  <br />
                  &nbsp;
                  <br />
                  제10조 (회원의 의무)
                  <br />① 회원은 다음 행위를 하여서는 안 됩니다.
                  <br />
                  &nbsp;1. 신청 또는 변경 시 허위내용의 기재
                  <br />
                  &nbsp;2. 타인의 정보도용
                  <br />
                  &nbsp;3. 회사의 임직원, 운영자, 기타 관계자를 사칭하는 행위
                  <br />
                  &nbsp;4. 회사가 게시한 정보의 변경
                  <br />
                  &nbsp;5. 회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는
                  게시
                  <br />
                  &nbsp;6. 회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나
                  기기 또는 장치를 제작, 배포, 이용, 광고하는 행위
                  <br />
                  &nbsp;7. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해
                  <br />
                  &nbsp;8. 회사 및 기타 제3자의 명예를 손상시키거나 업무를
                  방해하는 행위
                  <br />
                  &nbsp;9. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타
                  공서양속에 반하는 정보를 공개 또는 게시하는 행위
                  <br />
                  &nbsp;10. 회사의 동의 없이 영리, 영업, 광고, 정치활동 등을
                  목적으로 교육서비스를 사용하는 행위
                  <br />
                  &nbsp;11. 기타 관련 법령에서 금지하거나 선량한 풍속 기타
                  사회통념상 허용되지 않는 행위
                  <br />
                  &nbsp;12. 법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터
                  프로그램)나 컴퓨터 소프트웨어&sdot;하드웨어 또는
                  전기통신장비의 정상적인 작동을 방해&sdot;파괴할 목적으로
                  고안된바이러스&sdot;컴퓨터 코드&sdot;파일&sdot;프로그램 등을
                  고의로 전송&sdot;게시&sdot;유포 또는 사용하는 행위
                  <br />
                  &nbsp;13. 회사로부터 특별한 권리를 부여 받지 않고
                  애플리케이션을 변경하거나, 애플리케이션에 다른 프로그램을
                  추가&sdot;삽입하거나, 서버를 해킹&sdot;역설계하거나, 소스
                  코드나 애플리케이션 데이터를 유출&sdot;변경하거나, 별도의
                  서버를 구축하거나, 웹사이트의 일부분을 임의로
                  변경&sdot;도용하여 회사를 사칭하는 행위
                  <br />② 회원의 계정 및 모바일 기기에 관한 관리 책임은 회원에게
                  있으며, 이를 타인이 이용하도록 하게 하여서는 안 됩니다. 모바일
                  기기의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는
                  손해에 대해서 회사는 책임을 지지 않습니다.
                  <br />③ 회원은 이 약관의 규정, 이용안내 및 교육서비스와
                  관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 확인하고
                  준수할 의무가 있습니다.
                  <br />
                  <br />
                  제4장 서비스 이용
                  <br />
                  &nbsp;
                  <br />
                  제11조 (서비스의 변경 및 내용수정)
                  <br />① 회원은 회사가 제공하는 교육서비스를 이 약관 및 회사가
                  설정한 규정에 따라 이용할 수 있습니다.
                  <br />② 회사가 회원에게 제공하는 교육서비스에 대하여 회사는
                  컨텐츠의 제작, 변경, 유지, 보수에 관한 포괄적인 권한을
                  가집니다.
                  <br />③ 회사는 교육서비스의 악용을 막기 위하여 필요한 조치를
                  취합니다.
                  <br />④ 회사가 상당한 이유가 있는 경우에 운영상, 기술상의
                  필요에 따라 교육서비스 수정(패치)을 할 수 있으며, 교육서비스
                  수정(패치)을 하는 경우에는 변경 후 해당 교육서비스 등을 통하여
                  공지합니다.
                  <br />
                  &nbsp;
                  <br />
                  제12조 (서비스의 제공 및 중단 등)&nbsp;
                  <br />① 교육서비스는 회사의 영업방침에 따라 정해진 시간동안
                  제공합니다. 회사는 교육서비스 제공시간을 교육서비스 공지나
                  교육서비스 홈페이지(www.laudyou.com)에 적절한 방법으로
                  안내합니다.
                  <br />② 제1항에도 불구하고, 다음 각 호의 어느 하나에 해당하는
                  경우에는 일정한 시간동안 교육서비스가 제공되지 아니할 수
                  있으며, 해당 시간 동안 회사는 교육서비스를 제공할 의무가
                  없습니다.
                  <br />
                  &nbsp;1. 컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검
                  또는 컨텐츠나 교육서비스의 수정을 위하여 필요한 경우
                  <br />
                  &nbsp;2. 해킹 등의 전자적 침해사고, 통신사고, 회원들의
                  비정상적인 서비스 이용행태, 미처 예상하지 못한 교육서비스의
                  불안정성에 대응하기 위하여 필요한 경우
                  <br />
                  &nbsp;3. 관련 법령에서 특정 시간 또는 방법으로 교육서비스
                  제공을 금지하는 경우
                  <br />
                  &nbsp;4. 천재지변, 비상사태, 정전, 서비스 설비의 장애 또는
                  서비스 이용의 폭주 등으로 정상적인 교육서비스 제공이 불가능할
                  경우
                  <br />
                  &nbsp;5. 회사의 분할, 합병, 영업양도, 영업의 폐지, 당해
                  교육서비스의 수익 악화 등 회사의 경영상 중대한 필요에 의한
                  경우
                  <br />③ 회사는 제2항 제1호의 경우, 매주 또는 격주 단위로 일정
                  시간을 정하여 교육서비스를 중지할 수 있습니다. 이 경우 회사는
                  최소한 24시간 전에 그 사실을 회원에게 교육서비스 공지나
                  교육서비스 홈페이지(www.laudyou.com)에 고지합니다. &nbsp;
                  <br />④ 제2항 제2호의 경우, 회사는 사전 고지 없이 교육서비스를
                  일시 중지할 수 있습니다. 회사는 이러한 경우 그 사실을
                  교육서비스 공지나 교육서비스 홈페이지(www.laudyou.com)에 사후
                  고지할 수 있습니다.
                  <br />⑤ 회사는 회사가 제공하는 무료서비스 이용과 관련하여
                  이용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다.
                  다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의
                  경우는 제외합니다.
                  <br />⑥ 제2항 제3호 내지 제5호의 경우에 회사는 기술상․운영상
                  필요에 의해 교육서비스 전부를 중단할 수 있으며, 30일전에
                  홈페이지에 이를 공지하고 교육서비스의 제공을 중단할 수
                  있습니다. 사전에 통지할 수 없는 부득이한 사정이 있는 경우는
                  사후에 통지를 할 수 있습니다.
                  <br />⑦ 회사가 제7항에 따라 교육서비스를 종료하는 경우 회원은
                  무료서비스 및 사용 기간이 남아 있지 않은 유료서비스․계속적
                  유료 이용계약․기간제 유료아이템에 대하여 손해배상을 청구할 수
                  없습니다.
                  <br />
                  &nbsp;
                  <br />
                  제13조 (정보의 수집 등)
                  <br />① 회사는 교육서비스 내에서 회원이 작성한 모든 컨텐츠를
                  저장ㆍ보관할 수 있습니다. 회사는 고객 민원의 해결을 위하여
                  회사가 필요하다고 판단하는 경우에 한하여 본 정보를 열람하도록
                  할 것이며, 본 정보는 회사만이 보유하고 법령으로 권한을
                  부여받지 아니한 제3자는 절대로 열람할 수 없습니다. 회사는 해당
                  정보를 열람하기 전에 컨텐츠의 열람이 필요한 사유 및 열람
                  범위를 개인에게 사전 고지하기로 합니다. 다만, 계정도용,
                  현금거래, 언어폭력, 앱 내 사기 등 기망행위, 버그 악용, 기타
                  현행 법령 위반행위 및 이 약관 제10조에서 정하는 중대한
                  약관위반 행위의 조사, 처리, 확인 및 이의 구제와 관련하여
                  회원의 컨텐츠를 열람해야 할 필요가 있는 경우에는, 사후에
                  컨텐츠가 열람된 개인들에 대하여 열람한 사유와 열람한 정보 중
                  본인과 관련된 부분을 고지하기로 합니다.
                  <br />
                  &nbsp;② 회사는 교육서비스 운영 및 프로그램 안정화 등
                  교육서비스 품질 개선을 위하여 회원 모바일기기 등 단말기 설정
                  및 사양 정보를 수집&middot;활용할 수 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제14조 (이용상품)
                  <br />① 회사는 회원이 별도 비용지급 없이 이용할 수 있는
                  교육서비스(이하 &ldquo;무료서비스&rdquo;라 한다)와 회사가 미리
                  책정한 요금을 지불하고 이용하는 교육서비스(이하
                  &ldquo;유료서비스&rdquo;라 한다)를 제공할 수 있으며, 회원은
                  교육서비스를 선택하여 이용할 수 있습니다.
                  <br />② 교육서비스의 이용에 대한 대금지급방법은 회사가 미리
                  정한 방법을 통하여 할 수 있습니다.
                  <br />③ 제1항의 유료서비스 이용과 관련하여, 회원이 선택한
                  유료서비스 이용신청 후, 다음 각 호에 해당하는 사항을
                  준수하여야 합니다.
                  <br />
                  &nbsp;1. 유료서비스 이용을 신청한 회원은 교육서비스 이용요금을
                  성실히 납부하여야 합니다.
                  <br />
                  &nbsp;2. 유료서비스 이용을 신청한 회원은 교육서비스 이용요금을
                  신청 후 회사에서 제공하는 방법으로 지급하여야 합니다.
                  <br />
                  <br />
                  제15조 (저작권 등의 귀속)
                  <br />① 교육서비스 내 회사가 제작한 콘텐츠에 대한 저작권 기타
                  지적재산권은 회사의 소유입니다.
                  <br />② 회원은 회사가 제공하는 교육서비스를 이용함으로써 얻은
                  정보 중 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사
                  또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송
                  기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
                  하여서는 안 됩니다.
                  <br />③ 회사는 회원이 게시하거나 등록하는 교육서비스 내의
                  게시물, 게시 내용에 대해 제10조에서 규정하는 금지행위에
                  해당된다고 판단되는 경우, 사전통지 없이 이를 삭제하거나 이동
                  또는 등록을 거부할 수 있습니다.
                  <br />
                  ④회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상
                  이익이 침해된 회원은 회사에게 당해 정보의 삭제 또는 반박내용의
                  게재를 요청할 수 있습니다. 이 경우 회사는 신속하게 필요한
                  조치를 취하고, 이를 신청인에게 통지합니다.
                  <br />⑤ 제3항은 회사가 교육서비스를 운영하는 동안 유효하며
                  회원탈퇴 후에도 지속적으로 적용됩니다.
                  <br />
                  &nbsp;
                  <br />
                  제5장 청약철회, 계약 해제&middot;해지 및 이용제한
                  <br />
                  &nbsp;
                  <br />
                  제16조 (청약의 철회) &nbsp;
                  <br />① 회사와 유료서비스 이용에 관한 계약을 체결한 회원은
                  구매일 또는 유료서비스 이용가능일로부터 7일 이내에는 청약의
                  철회를 할 수 있습니다.
                  <br />
                  ②회원은 다음 각 호의 어느 하나에 해당하는 경우에는 회사의
                  의사에 반하여 제1항에 따른 청약철회 등을 할 수 없습니다.
                  <br />
                  &nbsp;1. 회원에게 책임이 있는 사유로 재화 등이 멸실되거나
                  훼손된 경우
                  <br />
                  &nbsp;2. 회원이 재화를 사용 또는 일부 소비한 경우
                  <br />
                  &nbsp;3. 시간이 지나 다시 판매하기 곤란할 경우
                  <br />
                  &nbsp;4. 복제가능한 재화 등의 포장을 훼손한 경우
                  <br />
                  &nbsp;5. 그 밖에 거래의 안전을 위하여 법령으로 정하는 경우
                  <br />③ 회원은 제1항 내지 제2항의 규정에도 불구하고
                  유료서비스의 내용이 표시&middot;광고 내용과 다르거나
                  계약내용과 다르게 이행된 경우에는 구매일 또는 유료서비스
                  이용가능일로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던
                  날부터 30일 이내에 청약철회를 할 수 있습니다.
                  <br />④ 회원은 구두 또는 서면(전자문서 포함), 전자우편으로
                  청약철회를 할 수 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제17조(청약 철회 등의 효과)
                  <br />① 회원이 청약철회를 한 경우 회사는 지체없이 회원의
                  유료서비스를 회수 또는 삭제하고 유료서비스를 회수 또는 삭제한
                  날로부터 3영업일 이내에 지급받은 대금을 환급합니다.
                  <br />② 이 경우 회사가 회원에게 환급을 지연한 때에는 그
                  지연기간에 대하여 전자상거래 등에서의 소비자보호에 관한 법률
                  및 시행령에서 정하는 이율을 곱하여 산정한 지연이자를
                  지급합니다
                  <br />③ 회사는 위 대금을 환급함에 있어서 회원이 신용카드나 그
                  밖에 전자상거래 등에서의 소비자보호에 관한 법률 시행령으로
                  정하는 결제수단으로 대금을 지급한 때에는 지체없이 당해
                  결제수단을 제공한 사업자로 하여금 대금의 청구를 정지 또는
                  취소하도록 요청합니다. 다만 회사가 결제업자로부터 이미 대금을
                  지급받은 때에는 이를 결제업자에게 환불하고 이를 소비자에게
                  통지합니다.
                  <br />④ 회원이 청약철회를 한 경우 재화 등의 반환에 필요한
                  비용은 회원이 부담하고, 회사는 회원에게 청약철회를 이유로
                  위약금 또는 손해배상을 청구할 수 없습니다.
                  <br />
                  &nbsp;
                  <br />
                  제18조 (회원의 해제 및 해지)
                  <br />① 회원은 교육서비스 이용계약을 해지(이하
                  &apos;회원탈퇴&apos;라 한다)할 수 있습니다. 회원이 회원탈퇴를
                  신청한 경우 회사는 회원 본인 여부를 확인할 수 있으며, 해당
                  회원이 본인으로 확인되는 경우에 회원의 신청에 따른 조치를
                  취합니다.
                  <br />② 회원이 회원탈퇴를 원하는 경우에는 고객센터 및 서비스
                  내 회원탈퇴 절차를 통하여 회원탈퇴를 할 수 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제19조 (회사의 해제 및 해지)&nbsp;
                  <br />① 회사는 회원이 이 약관에서 정한 회원의 의무를 위반한
                  경우에는 회원에 대한 사전 통보 후 계약을 해지할 수 있습니다.
                  다만, 회원이 현행법 위반 및 고의 또는 중대한 과실로 회사에
                  손해를 입힌 경우에는 사전 통보 없이 이용계약을 해지할 수
                  있습니다.
                  <br />② 회사가 이용계약을 해지하는 경우 회사는 회원에게 서면,
                  전자우편 또는 이에 준하는 방법으로 다음 각 호의 사항을
                  회원에게 통보합니다.
                  <br />
                  &nbsp;1. 해지사유
                  <br />
                  &nbsp;2. 해지일
                  <br />③ 제1항 단서의 경우, 회원은 유료서비스의 사용권한을
                  상실하고 이로 인한 환불 및 손해배상을 청구할 수 없습니다.
                  <br />
                  &nbsp;
                  <br />
                  제20조 (잠정조치로서의 이용제한)
                  <br />① 회사는 다음 각 호에 해당하는 문제에 대한 조사가 완료될
                  때까지 계정을 정지할 수 있습니다.
                  <br />
                  &nbsp;1. 계정이 해킹 또는 도용당하였다는 정당한 신고가 접수된
                  경우
                  <br />
                  &nbsp;2. 불법프로그램 사용자, 작업장 등 위법행위자로
                  합리적으로 의심되는 경우
                  <br />
                  &nbsp;3. 그 밖에 위 각호에 준하는 사유로 계정의 잠정조치가
                  필요한 경우
                  <br />
                  <br />
                  제6장 손해배상 및 환불 등<br />
                  &nbsp;
                  <br />
                  제21조 (손해배상)
                  <br />① 회사가 고의 또는 중과실로 회원에게 손해를 끼친 경우,
                  손해에 대하여 배상할 책임이 있습니다.
                  <br />② 회원이 본 약관을 위반하여 회사에 손해를 끼친 경우,
                  회원은 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제22조 (환불)
                  <br />① 회원이 직접 구매한 캐시의 환불을 요청하면 이를 환불
                  받을 수 있습니다. 환불 시에는 은행이체 및 결제대행 수수료 등의
                  사유로 현재 남아 있는 캐시 잔액의 10%이내 금액 또는 캐시잔액이
                  10,000원 이내인 경우에는 일정금액을 공제하고 남은 금액을
                  환불하여 드립니다. 일정금액을 공제하는 경우 공제 후 남은
                  금액이 공제금액보다 작은 경우에는 환불할 수 없습니다.
                  <br />② 계속적 이용계약의 경우에는 상품가액에서 이용자가 이미
                  사용한 부분에 해당하는 금액(각 상품별 사용금액 산정기준은 상품
                  구매 시 별도 고지함)을 제외하고 남은 금액에서 남은금액의 10%
                  이내 금액 또는 남은 금액이 10,000원 이내인 경우에는 일정금액을
                  공제한 후 나머지 금액을 환불해 드립니다. 일정금액을 공제하는
                  경우 공제 후 남은 금액이 공제금액보다 작은 경우에는 환불할 수
                  없습니다.
                  <br />③ 현행법령 및 중대한 약관 위반 등 회원의 귀책사유로
                  이용계약을 해지하는 경우 환불이 제한될 수 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제23조 (회사의 면책)
                  <br />① 회사는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는
                  해결이 불가능한 기술적 결함 기타 불가항력적 사유로 서비스를
                  제공할 수 없는 경우에는 책임이 면제됩니다.
                  <br />
                  ②회사는 회원의 귀책사유로 인한 교육서비스의 중지, 이용장애 및
                  계약해지에 대하여 책임이 면제됩니다.
                  <br />
                  ③회사는 기간통신 사업자가 전기통신서비스를 중지하거나
                  정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에
                  대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이
                  면제됩니다.
                  <br />
                  ④회사는 사전에 공지된 교육서비스용 설비의 보수, 교체,
                  정기점검, 공사 등 부득이한 사유로 교육서비스가 중지되거나
                  장애가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이
                  없는 한 책임이 면제됩니다.
                  <br />
                  ⑤회사는 회원의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는
                  회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여
                  발생하는 문제에 대해서 책임이 면제됩니다.
                  <br />
                  ⑥회사는 회원 또는 제3자가 교육서비스 내 또는 웹사이트 상에
                  게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에
                  대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이
                  면제됩니다.
                  <br />
                  ⑦회사는 회원 상호간 또는 회원과 제3자간에 교육서비스를 매개로
                  발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를
                  배상할 책임도 없습니다.
                  <br />
                  ⑧회사가 제공하는 교육서비스 중 무료서비스의 경우에는 회사의
                  고의 또는 중대한 과실이 없는 한 회사는 손해배상을 하지
                  않습니다.
                  <br />
                  ⑨본 교육서비스 중 일부의 교육서비스는 다른 사업자가 제공하는
                  교육서비스를 통하여 제공될 수 있으며, 회사는 다른 사업자가
                  제공하는 교육서비스로 인하여 발생한 손해 등에 대해서는 회사의
                  고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
                  <br />
                  ⑩회사는 회원의 스마트폰 오류에 의한 손해가 발생한 경우 또는
                  신상정보 및 추천코드를 부정확하게 기재하거나 미기재하여 손해가
                  발생한 경우에 대하여 회사의 고의 또는 중대한 과실이 없는 한
                  책임이 면제됩니다.
                  <br />
                  ⑪회사는 관련 법령, 정부 정책 등에 따라 교육서비스 또는 회원에
                  따라 교육서비스 이용시간 등을 제한할 수 있으며, 이러한
                  제한사항 및 제한에 따라 발생하는 교육서비스 이용 관련
                  제반사항에 대해서는 책임이 면제됩니다.
                  <br />
                  &nbsp;
                  <br />
                  제24조 (회원의 고충처리 및 분쟁해결)
                  <br />
                  ①회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는
                  방법을 교육서비스 공지나 교육서비스 홈페이지에서 안내합니다.
                  회사는 이러한 회원의 의견이나 불만을 처리하기 위한 전담조직을
                  운영합니다.
                  <br />
                  ②회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고
                  객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게
                  처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게
                  장기간이 소요되는 사유와 처리일정을 교육서비스
                  홈페이지(www.laudyou.com)에 공지하거나 전자우편, 전화 또는
                  서면 등으로 통보합니다.
                  <br />③ 회사와 회원간에 분쟁이 발생하여 제3의 분쟁조정기관이
                  조정할 경우 회사는 이용제한 등 회원에게 조치한 사항을 성실히
                  증명하고, 조정기관의 조정에 따를 수 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제25조 (회원에 대한 통지)
                  <br />① 회사가 회원에게 통지를 하는 경우 회원이 지정한
                  전자우편주소, &nbsp;전자메모 등으로 할 수 있습니다.
                  <br />② 회사는 회원 전체에게 통지를 하는 경우 7일 이상 회사의
                  교육서비스 공지에 게시하거나 팝업화면 등을 제시함으로써
                  제1항의 통지에 갈음할 수 있습니다.
                  <br />
                  &nbsp;
                  <br />
                  제26조 (재판권 및 준거법)
                  <br />본 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사와
                  회원간에 발생한 분쟁으로 소송이 제기되는 경우, 법령에 정한
                  절차에 따른 법원을 관할 법원으로 합니다.
                  <br />
                  <br />
                  공고일자 : 2023년 11월 1일
                  <br />
                  시행일자 : 2023년 12월 1일
                  <br />
                  <br />
                </p>
              </Text>
            </PageForm>
          </Page>
        </div>
        <Footer />
      </PageLayout>
    </Layout>
  );
}

const Layout = styled.div`
  height: 100%;

  background: #f0f0f0;
`;

const PageLayout = styled.div`
  height: 100%;
`;

const Page = styled.div`
  width: 1000px;

  padding: 30px;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    width:100%;
    padding:0px;
  }
`;
const PageForm = styled.div`
  padding: 80px 100px 394px;
  background: white;
  @media (max-width: 800px) {
    padding: 25px;
  }
`;

const Title = styled.div`
  width: 800px;

  color: #333;
  font-size: 38px;
  font-weight: 600;
  line-height: 40px;

  padding-bottom: 40px;
  border-bottom: 1px solid #b1b1b1;
  margin-bottom: 40px;

  @media (max-width: 800px) {
    width:100%;
  }
`;
const Text = styled.div`
  width: 800px;

  color: #444;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;

  padding-bottom: 80px;
  border-bottom: 1px solid #b1b1b1;

  text-align: left;

  @media (max-width: 800px) {
    width:100%;
  }
`;
