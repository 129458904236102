import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Footer() {
  return (
    <div style={{
      width: "100%",
      height: "350px"
    }}>
      <div style={{ height: "50px", backgroundColor:"#D1F5AB" }}></div>
      <div
        style={{
          width: "100%",
          height: "300px",
          backgroundColor: "#D1F5AB",
          backgroundImage: `url("/images/footer.svg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Layout>
          <div
            style={{
              display: "flex",
              gap: "25px"
            }}
          >
            <Link to={"/useTerms"}
              style={{
                textDecorationLine: "none",
                textDecorationColor: "white",
                color: "white",
                fontSize: "18px",
                fontWeight: "700"
              }}
            >이용약관</Link>
            <Link to={"/privacyPolicy"}
              style={{
                textDecorationLine: "none",
                textDecorationColor: "white",
                color: "white",
                fontSize: "18px",
                fontWeight: "700"
              }}
            >개인정보처리방침</Link>
          </div>
          <p
            style={{
              paddingTop: "20px",
              fontSize: "16px",
              fontWeight: "normal"
            }}
          >Copyright © FineTeacher Inc. All rights reserved.</p>
        </Layout>
      </div>
    </div>
  );
}

const Layout = styled.div`
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding-top: 118px;
  color: white;

  @media (max-width: 800px) {
    width: calc(100% - 50px);
    max-width: 600px;
    padding: 118px 25px 0px 25px;
  }
`;