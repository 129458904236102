import { isMobile } from "react-device-detect";
import styled from "styled-components";

export default function Title() {
  return (
    <Layout>
      <Header>
        <img alt="logo" src="/images/laudyou-logo.svg" />
        <span>LAUD YOU</span>
      </Header>
      <TitleForm>
        <div className="firstTitle">
          <h1><strong style={{ fontWeight: 900 }}>LAUD YOU</strong>는</h1>
        </div>
        <div className="secondTitle">
          {isMobile ? (<h2>새로운 공부 경험을<br />제공합니다.</h2>) : (<h2><strong style={{ fontWeight: 600 }}>새로운 공부 경험</strong>을 제공합니다.</h2>)}
        </div>
        <h3>공부와 칭찬을 통한 휴대폰 관리</h3>
      </TitleForm>
    </Layout>
  );
}

const Layout = styled.div`
  margin-bottom: 185px;

  color: white;

  div {
    display: flex;
  }

  .firstTitle {
    h1,h2 {
      font-size: 74px;
      line-height: 70px;
    }
    h1,h2:first-child {
      font-weight: 900;
      letter-spacing: -0.74px;
    }
    h1,h2:nth-child(2) {
      font-weight: 400;
    }
  }

  .secondTitle {
    display: flex;

    h1,h2 {
      font-size: 55px;
      line-height: 70px;
      div {
        font-weight: 600;
      }
    }
  }

  h3 {
    color: #fefff3;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    margin: 15px 0 0 2px;
  }

  @media (max-width: 800px) {
    margin-bottom: 54px;
    .firstTitle {
      h1,h2 {
        font-size: 32px;
        line-height: 38px;
      }
      h1,h2:first-child {
        font-weight: 800;
        letter-spacing: 0;
      }
      h1,h2:nth-child(2) {
        font-weight: 400;
      }
    }
    .secondTitle {
      h1,h2 {
        font-size: 32px;
        line-height: 38px;
        div {
          font-weight: 400;
        }
      }
      h1,h2:first-child {
        font-weight: 400;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 18px;

      margin: 0;
      margin-top: 18px;
    }
  }
`;

const Header = styled.div`
  margin: 70px 0 110px;

  span {
    font-size: 30px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: -0.3px;

    display: flex;
    align-items: center;

    margin-left: 15px;
  }

  @media (max-width: 800px) {
    margin: 10px 20px 70px 20px;

    display: flex;
    gap: 10px;

    img {
      width: 40px;
    }
    span {
      font-size: 20px;
      font-weight: 700;

      margin: 0;
    }
  }
`;

const TitleForm = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-left: 30px;
  }
`;
