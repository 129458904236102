import styled from "styled-components";
import ContentTitle from "./content-title";
import Step from "./step";
import Downloads from "./downloads";
import { useEffect, useRef, useState } from "react";
import FirstBox from "./select-box/first-box";
import SecondBox from "./select-box/second-box";
import ThirdBox from "./select-box/third-box";
import FourthBox from "./select-box/fourth-box";
import { isMobile } from 'react-device-detect'
import { useSearchParams } from "react-router-dom";

export default function Method() {
  const StepProp = [
    {
      stepNumber: "01",
      title: "라우드유 앱 다운로드 하기",
      text: `구글 플레이스토어 또는 애플 앱스토어에서 라우드유 앱을 다운 받아서 설치하세요.\n라우드유는 같은 운영체제의 기기로 연결되어야 더 원활하게 이용하실 수 있습니다.`,
    },
    {
      stepNumber: "02",
      title: "자녀 등록하여 연결하기",
      text: "자녀 공부앱에서 자녀 이름을 등록하고 부모 관리앱의 QR 코드 또는 숫자코드를 입력하세요.\n부모 휴대폰에서 부모 관리앱을 설치하고 로그인 후 보여지는 QR코드 또는 숫자코드를 통해 자녀를 등록하여 연결이 가능합니다.",
    },
    {
      stepNumber: "03",
      title: "자녀 휴대폰 관리 설정하기",
      text: "부모관리앱과 자녀공부앱이 연결된 후 부모관리앱에서 공부 방식에 맞춰 학습 과정을 제공 받을 수 있으며,\n자녀 휴대폰을 제어할 수 있습니다.",
    },
    {
      stepNumber: "04",
      title: "관리 및 학습 설정하기",
    },
    {
      stepNumber: "05",
      title: "학습 및 칭찬과 보상 받기",
      text: "부모앱에서 설정한 학습과정과 공부방식에 맞춰서 공부를 진행하고 보상으로 받은 시간 동안 휴대폰을 사용해보세요.",
    },
  ];
  const layoutRef = useRef(null);
  const [selected, setSelected] = useState<string>("first");

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const focus = searchParams.get("focus");
    if (focus && focus == "method") {
      //@ts-ignore
      layoutRef?.current?.scrollIntoView({ behavior: "smooth" });
    }

  }, [])

  const changeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(e.target.value);
  };

  const selectedComponent = () => {
    if (selected === "first") {
      return <FirstBox />;
    }
    if (selected === "second") {
      return <SecondBox />;
    }
    if (selected === "third") {
      return <ThirdBox />;
    }
    if (selected === "fourth") {
      return <FourthBox />;
    }
  };

  return (
    <div
      ref={layoutRef}
      style={{
        position: "relative",
      }}
    >
      <div style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "700px",
        backgroundImage: `url("/images/footer-top.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}></div>
      <Layout>
        <ContentTitle title="이용방법" topText="시작하기위한" />
        <YouTubeLayout>
          <iframe
            src="https://www.youtube.com/embed/MA4wBjfNUBI?si=B0DRRvr_uy3h5oKA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
          ></iframe>
        </YouTubeLayout>
        <YouTubeButtonLayout>
          <div style={{ fontSize: "30px", fontWeight: 600, color: "#555555" }}>
            <p style={{ color: "#76D300", paddingBottom: "10px" }}>라우드유 유튜브로 방문해</p>
            <p>더 많은 정보를 확인해보세요</p>
          </div>
          <button
            style={{
              maxWidth:"500px",
              width: "100%",
              height: "100px",
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
              border: "none",
              fontSize: "28px",
              fontWeight: 700,
              color: "#555555",
              boxShadow: "0 0 30px 0 rgba(211, 225, 192, 1)",
              cursor: "pointer"
            }}
            onClick={() => {
              window.open("https://www.youtube.com/@LaudYou", "__blank")
            }}
          >
            라우드유 <span style={{ color: "#EF2626" }}>유튜브</span> 바로가기
          </button>
        </YouTubeButtonLayout>
        <div className="boxLayout">
          <BoxHeader />
          <Box>
            <BoxForm>
              {isMobile && <div className="line" style={{ marginTop: "0px" }}></div>}
              <div className="firstStep">
                <Step
                  stepNumber={StepProp[0].stepNumber}
                  title={StepProp[0].title}
                  text={StepProp[0].text}
                />
                <Downloads />
              </div>
              <div className="line">
                <Step
                  stepNumber={StepProp[1].stepNumber}
                  title={StepProp[1].title}
                  text={StepProp[1].text}
                />
                <img style={{ width: "100%" }} alt="connect" src={isMobile ? "/images/method/step-two-m.png" : "/images/method/step-two.png"} />
              </div>
              <div className="line">
                <Step
                  stepNumber={StepProp[2].stepNumber}
                  title={StepProp[2].title}
                  text={StepProp[2].text}
                />
                <img style={{ width: "100%" }} alt="connect" src={isMobile ? "/images/method/step-three-m.png" : "/images/method/step-three.png"} />
              </div>
              <div className="line">
                <Step
                  stepNumber={StepProp[3].stepNumber}
                  title={StepProp[3].title}
                />
                <Select onChange={changeSelect} defaultValue={"first"}>
                  <option value="first">01 앱 관리 설정 방법</option>
                  <option value="second">02 학습 과정 구매 및 설정 방법</option>
                  <option value="third">03 학습 방식 설정 방법</option>
                  <option value="fourth">04 일정 관리 방법</option>
                </Select>
                {selectedComponent()}
              </div>
              <div className="line">
                <Step
                  stepNumber={StepProp[4].stepNumber}
                  title={StepProp[4].title}
                  text={StepProp[4].text}
                />
                <CardWrapper>
                  <Card>
                    <div>
                      <img alt="connect" src="/images/method/heart.png" />
                      <div className="parents">부모휴대폰 (부모관리앱)</div>
                      <p>
                        자녀 공부에 필요한 학습 과정을
                        <br />
                        구매하여 공부를 시켜보세요.
                        <br />
                        그리고 열심히 공부한 자녀를 많이 칭찬해주세요.
                      </p>
                    </div>
                  </Card>
                  <Card>
                    <div>
                      <img alt="connect" src="/images/method/gift.png" />
                      <div className="children">자녀휴대폰 (자녀공부앱)</div>
                      <p>
                        부모님께 열심히 공부하는 모습을
                        <br />
                        보여드리고 칭찬을 받아보세요.
                        <br />
                        칭찬과 더불어서 선물도 기대해보면 어떨까요?
                      </p>
                    </div>
                  </Card>
                </CardWrapper>
              </div>
            </BoxForm>
          </Box>
        </div>

      </Layout>

    </div>
  );
}

const Layout = styled.div`
  width: 1050px;
  margin: 0 auto;

  .boxLayout {
    display: flex;
    justify-content: center;

    margin-top: 72px;
  }
  @media (max-width: 800px) {
    width: 100%;
    .boxLayout {
      margin-top: 0px;
    }
  }
`;

const BoxHeader = styled.div`
  width: 1050px;

  height: 147px;

  border-radius: 60px;
  background: #76d300;

  box-shadow: 0px 0px 30px 0px rgba(87, 89, 68, 0.2);

  position: absolute;

  @media (max-width: 800px) {
    width: 100%;
    display: none;
  }
`;

const Box = styled.div`
  width: 100%;
  maxWidth: 1050px;

  background: white;

  border-radius: 60px;
  box-shadow: 0px 0px 30px 0px rgba(87, 89, 68, 0.2);

  position: relative;
  top: 20px;
  
  @media (max-width: 800px) {
    border-radius: 0px;
    box-shadow: none;
  }
`;

const BoxForm = styled.div`
  padding: 100px;

  .firstStep {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .line {
    margin-top: 50px;
    border-top: 1px dashed #888;
    padding-top: 50px;

    > img {
      margin-top: 32px;
    }
  }

  @media (max-width: 800px) {
    padding: 25px;
  }
`;

const Select = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: url("/images/method/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: center right 30px;

  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #f6f6f6;

  border: none;
  border-bottom: 1px solid black;
  margin-top: 25px;

  position: relative;

  color: #333;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 800px) {
    flex-flow: column;
  }
`;
const Card = styled.div`
  width: 390px;
  height: 266px;

  border-radius: 10px;
  border: 1px solid #d9dfd6;
  background: #fff;

  margin-top: 35px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }

  div > div {
    margin-top: 20px;
  }
  .parents {
    color: #fb6b18;
  }
  .children {
    color: #68c207;
  }

  p {
    color: #666;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    margin-top: 10px;
  }

  img {
    width: 70px;
    margin-top: 35px;
  }
  @media (max-width: 800px) {
    width: 100%;

    text {
      padding:0 50px;
    }
  }
`;

const YouTubeLayout = styled.div`
  max-width: 942px;
  width: 100%;
  height: 0;
  margin: 0 auto;
  margin-top: 60px;
  border: solid 1px #E3E3E3;
  position: relative;
  padding-top:57%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @media (max-width: 800px) {
    padding-top:51%;
    width: calc(100% - 60px);
    margin-right:30px;
    margin-left:30px;
  }
`;

const YouTubeButtonLayout = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    width: calc(100% - 60px);
    padding-right:30px;
    padding-left:30px;
    display: block;

    div {
      text-align: center;
      padding-bottom:20px;
      font-size:20px !important;
    }
    button {
      max-width: none !important;
      font-size:18px !important;
      height: 80px !important;
    }
  }

`;